import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncIcon from '@mui/icons-material/Sync';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import ScheduleIcon from '@mui/icons-material/Schedule';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


var BACKEND_URL = 'https://backend.bt-support.net/api/';
export default function AfficherAgence() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [agence, setAgence] = useState([]);
    const [nomAgence, setnomAgence] = useState('');
    const [villeAgence, setVilleAgence] = useState('');
    const [latitude, setlatitude] = useState('');
    const [longitude, setlongitude] = useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
   
    const { agence_id } = useParams();
    const { client_id } = useParams();
    const { compte_id } = useParams();


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}agence/show/${agence_id}/${compte_id}`)
        .then(({data}) => {
            setAgence(data);
            console.log(agence);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

    const modifierAgence = async (e) => {
      if(nomAgence==null || nomAgence=='')
      {
        setBackendErrorMessage('Veuillez entrer le nom de l\'agence')
      }
      else if(villeAgence==null || villeAgence=='')
      {
        setBackendErrorMessage('Veuillez entrer la ville de l\'agence')
      }     
      else {
      await axios.put(`${BACKEND_URL}agence/update/${agence_id}/${compte_id}`, {
                    nomAgence: nomAgence,
                    villeAgence: villeAgence,
                    latitude: latitude,
                    longitude: longitude
                }).then((data) =>{
                  request();
                  console.log(data)
                  setOpenDialog(false);
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
      });
    }
    };

    

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };
    
    const handlePrint = () => {
        /*const printWindow = window.open('', '_blank');
        
          const tableRows = 
            `
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Client</td>
              <td align="center" style={{fontWeight: 600}}>${client.nomClient}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Type client</td>
              <td align="center" style={{fontWeight: 600}}>${client.typeClient}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Code</td>
              <td align="center" style={{fontWeight: 600}}>${client.codeClient}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Boite postale</td>
              <td align="center" style={{fontWeight: 600}}>${client.boitePostale ==null ? '' : client.boitePostale}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Adresse</td>
              <td align="center" style={{fontWeight: 600}}>${client.adresseClient ==null ? '' : client.telClient}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Téléphone</td>
              <td align="center" style={{fontWeight: 600}}>${client.telClient}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>E-mail</td>
              <td align="center" style={{fontWeight: 600}}>${client.mailClient ==null ? '' : client.mailClient}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>RCCM</td>
              <td align="center" style={{fontWeight: 600}}>${client.rccm==null ? '' : client.rccm}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Numéro IFU</td>
              <td align="center" style={{fontWeight: 600}}>${client.numeroIFU == null ? '' : client.numeroIFU}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Régime normale</td>
              <td align="center" style={{fontWeight: 600}}>${client.regimeNormale == null || 0 ? '' : client.regimeNormale}</td>
            </tr>

            <!-- Ajoutez les autres lignes ici -->
          `;
          
          // Utilisez la chaîne HTML générée pour construire le document HTML
          const htmlContent = `
            <html lang="fr">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Détails du client ${client.nomClient}</title>                
                <style>
                  body { font-family: Arial, sans-serif; }
                  table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                  th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                  th { background-color: #f2f2f2; }
                </style>
              </head>
              <body>
                <h2>Détails du client ${client.nomClient}</h2>
                <table>
                  <thead>
                    <tr>
                     </tr>
                  </thead>
                  <tbody>
                    ${tableRows}
                  </tbody>
                </table>
              </body>
            </html>`;
          
          // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
          printWindow.document.write(htmlContent);
          printWindow.document.close();
          printWindow.print();*/
      };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
  
          window.addEventListener('resize', handleResize);
        
            return () => {
              window.removeEventListener('resize', handleResize);
          };
          }, []);


  const navigate = useNavigate();

    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`clients/${client_id}/${compte_id}/agences/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <div></div>             
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: windowWidth>800?20:2, 
                      marginRight: windowWidth>800?20:2, 
                      borderRadius: 5,
                      paddingTop: 5,
                      paddingBottom: 5,
                      boxShadow: '0px 0px 5px #0802A3',
                      backgroundColor:'white',
                      minWidth: windowWidth>700?600:200}}>                  
                  <Stack>
                    {windowWidth>800?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0802A3',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Détails de l'agence {agence.nomAgence}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>                                    
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Agence</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{agence.nomAgence}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Ville agence</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{agence.villeAgence}</TableCell>
                                    </TableRow>                                   
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Longitude</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{agence.longitude == null ? '' : agence.longitude}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Latitude</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{agence.latitude  == null ? '' : agence.latitude}</TableCell>
                                    </TableRow>
                                                                       
                                </TableBody>
                            </Table>
                    </TableContainer>                   
                   :
                   <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 150,maxWidth:500 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0802A3',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Détails de l'agence {agence.nomAgence}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>                                    
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Agence</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Ville agence</TableCell>
                                    </TableRow>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 0}}>{agence.nomAgence}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{agence.villeAgence}</TableCell>
                                    </TableRow>                                   
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Longitude</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Latitude</TableCell>
                                    </TableRow>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 0}}>{agence.longitude == null ? '' : agence.longitude}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{agence.latitude  == null ? '' : agence.latitude}</TableCell>
                                    </TableRow>
                                                                       
                                </TableBody>
                            </Table>
                    </TableContainer>
                   
                    }
                  </Stack> 
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => {
                    setnomAgence(agence.nomAgence)
                    setVilleAgence(agence.villeAgence)
                    setlatitude(agence.latitude)
                    setlongitude(agence.longitude)                    
                    handleClickOpenDialog()
                    }} >
                    Modifier                      
            </Button>                
            </Stack>

            <Dialog
                  //fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information de l'agence"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >       
                        {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}                   
                          <Stack
                              direction={windowWidth>800?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label="Nom de l'agence"
                                value={nomAgence}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                  setnomAgence(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Ville'
                                value={villeAgence}
                                onChange={(e)=> {
                                    setVilleAgence(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                          <Stack
                              direction={windowWidth>800?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label="Longitude"
                                value={longitude}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                  setlongitude(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Latitude'
                                value={latitude}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                    setlatitude(e.target.value)
                                }}
                            ></TextField>
                          </Stack>                                                    
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {modifierAgence()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>
            
        </div>
    );
    
}