import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../composants/nav.js';
import openCom from './images/openCom.png';
import carDelivery from './images/carDelivery.png';
import agence from './images/agence.png';
import client from './images/client.png';
import entrepots from './images/entrepots.png';
import intervClos from './images/intervClos.png';
import intervCours from './images/intervCours.png';
import tresor from './images/tresor.png';
import user from './images/user.png';
import ventes from './images/ventes.png';
import './dasboard.css';
import { useParams,useNavigate } from "react-router-dom";
import {Dialog,DialogActions,Typography, Box,DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete} from '@mui/material';
import B from './images/B.png';
import B2 from './images/B2.png';
import H from './images/H.png';
import hand from './images/hand.png';
import laptop from './images/laptop.png';
import lock from './images/lock.png';
import T from './images/T.png';
import techni from './images/techni.png';
import wallet from './images/wallet.png';
import AddIcon from '@mui/icons-material/Add.js';

import Lottie from 'lottie-react';
import celebrat from './images/lottie/personCelebrate.json'


const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];


  var BACKEND_URL = 'https://backend.bt-support.net/api/';




export default function Dashboard() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [approData, setApproData] = useState([]);
  const [entrepotData, setEntrepotData] = useState([]);
  const [relSortieData, setRelSortieData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [agenceData, setAgenceData] = useState([]);
  const [intervCoursData, setIntervCoursData] = useState([]);
  const [intervClosData, setIntervClosData] = useState([]);
  const [soldeActuelData, setSoldeActuelData] = useState([]);
  const [backendErrorMessage, setBackendErrorMessage] = useState('');
  const { compte_id } = useParams();
  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};


    const request = async () => {
      try {                      
        await axios.get(`${BACKEND_URL}approvisionement/approActuel/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setApproData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}entrepots/entrepotActuel/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setEntrepotData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}releveSortie/relSortieActuel/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setRelSortieData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}utilisateurs/technicien/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setUserData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}client/clientActuel/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setClientData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}agence/agenceActuel/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setAgenceData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}ticket_intervention/intervCours/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setIntervCoursData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}ticket_intervention/intervClos/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setIntervClosData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}tresorerie/soldeActuel/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setSoldeActuelData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
  }

useEffect(() => {
    request();
  const timer = setTimeout(() => {
    // Mettez à jour l'état pour arrêter le chargement après 3 secondes
    setLoading(false);
  }, 3000); // 3000 millisecondes = 3 secondes

  // Nettoyez le timer lorsque le composant est démonté
  const interval = setInterval(() => {
    setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
  }, 300);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
};
window.addEventListener('resize', handleResize);

  return () => {
    clearTimeout(timer);
    clearInterval(interval);
    window.removeEventListener('resize', handleResize);
};
}, []);
const [loading, setLoading] = useState(true);
const [currentImage, setCurrentImage] = useState(0);

if(!loading) {

    return (
        <div>
            <Nav compte_id={compte_id}/>
            <div class="container-fluid">
            <div class="rounded-container" style={{margin:10,
             //backgroundColor: 'white',
             border: '1px solid blueViolet',
             width:windowWidth>600?510:'',
               backgroundColor:'white'}}>
                <Stack direction={'row'}>
                <Typography
                  style={{
                    display: 'flex', // Utilisation de flexbox
                    justifyContent: 'center', // Centre horizontalement
                    alignItems: 'center', // Centre verticalement
                    height: '50px', // Hauteur du conteneur
                    textAlign: 'center', // Alignement du texte
                    fontWeight:'bold',
                    color:'red',
                    fontStyle:'italic',
                    marginRight:10
                  }}
                >
                  BT-Support vous souhaite de joyeuses fêtes de fin d'année
                </Typography>
                  <Lottie animationData={celebrat} loop style={{
                            height:50,
                            width:50
                        }}/>
                </Stack>
                </div>
                <div class="row">
                  <div class="col-sm-3" style={{ backgroundColor: '#fcfcfc'}}>
                    <div class="container">
                        <div class="titre-text">
                          <p>Stock</p>
                        </div>
                        <div class="rounded-container">
                                <div class="flex-row">
                                  <div>
                                    <p style={{ fontSize: 20,marginBottom: -5}}>{approData==null?0:approData}</p>
                                  </div>
                                  <div style={{ width: '30px', height: '30px' }}>
                                          <a onClick={() => {}/*handleLinkClick(`approvisionements/${compte_id}`)*/}> 
                                              <img src={openCom}
                                                width= '100%' 
                                                height='100%'
                                              />
                                          </a>
                                  </div>
                                </div>
                              <p style={{fontSize: 14, fontWeight: 'bold'}}>Approvisionement</p>
                              <div style={{ width: '100px', height: '70px' }}>
                                          <img src={carDelivery}
                                            width= '100%' 
                                            height='100%'
                                           /> 
                              </div>
                        </div>
                        <div class="rounded-container">
                                <div class="flex-row">
                                  <div>
                                    <p style={{ fontSize: 20,marginBottom: -5}}>{entrepotData==null?0:entrepotData}</p>
                                  </div>
                                  <div style={{ width: '30px', height: '30px' }}>
                                          <a  onClick={() => {}/*handleLinkClick(`configurationStock/${compte_id}/entrepots/${compte_id}`)*/}> 
                                              <img src={openCom}
                                                width= '100%' 
                                                height='100%'
                                              />
                                          </a>
                                  </div>
                                </div>
                              <p style={{fontSize: 14, fontWeight: 'bold'}}>Entrepot</p>
                              <div style={{ width: '80px', height: '70px' }}>
                                          <img src={entrepots}
                                            width= '100%' 
                                            height='100%'
                                           /> 
                              </div>
                        </div>
                        <div class="rounded-container">
                                <div class="flex-row">
                                  <div>
                                    <p style={{ fontSize: 20,marginBottom: -5}}>{relSortieData==null?0:relSortieData}</p>
                                  </div>
                                  <div style={{ width: '30px', height: '30px' }}>
                                          <a onClick={() => {}/*handleLinkClick(`sorties/${compte_id}`)*/}> 
                                              <img src={openCom}
                                                width= '100%' 
                                                height='100%'
                                              />
                                          </a>
                                  </div>
                                </div>
                              <p style={{fontSize: 14, fontWeight: 'bold'}}>Relevée Sorties</p>
                              <div style={{ width: '80px', height: '70px' }}>
                                          <img src={ventes}
                                            width= '100%' 
                                            height='100%'
                                           /> 
                              </div>
                        </div>
                  </div>
                  </div>
                 <div class="col-sm-5" style={{ backgroundColor: '#fcfcfc'}}>
                    <div class="container">
                        <div class="titre-text">
                          <p>Statistique générale</p>
                        </div>
                        <div class="row">
                          <div class="col">
                          <div class="rounded-container">
                                <div class="flex-row">
                                  <div>
                                    <p style={{ fontSize: 20,marginBottom: -5}}>{clientData==null?0:clientData}</p>
                                  </div>
                                  <div style={{ width: '30px', height: '30px' }}>
                                          <a onClick={() => {}/*handleLinkClick(`clients/${compte_id}`)*/}> 
                                              <img src={openCom}
                                                width= '100%' 
                                                height='100%'
                                              />
                                          </a>
                                  </div>
                                </div>
                              <p style={{fontSize: 14, fontWeight: 'bold'}}>Clients</p>
                              <div style={{ width: '70px', height: '60px' }}>
                                          <img src={client}
                                            width= '100%' 
                                            height='100%'
                                           /> 
                              </div>
                            </div>
                          </div>
                          <div class="col">
                          <div class="rounded-container">
                                <div class="flex-row">
                                  <div>
                                    <p style={{ fontSize: 20,marginBottom: -5}}>{agenceData==null?0:agenceData}</p>
                                  </div>
                                  <div style={{ width: '30px', height: '30px' }}>
                                          <a onClick={() => {}/*handleLinkClick(`clients/${compte_id}`)*/}> 
                                              <img src={openCom}
                                                width= '100%' 
                                                height='100%'
                                              />
                                          </a>
                                  </div>
                                </div>
                              <p style={{fontSize: 14, fontWeight: 'bold'}}>Agences</p>
                              <div style={{ width: '70px', height: '60px' }}>
                                          <img src={agence}
                                            width= '100%' 
                                            height='100%'
                                           /> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                          <div class="rounded-container">
                                <div class="flex-row">
                                  <div>
                                    <p style={{ fontSize: 20,marginBottom: -5}}>{intervCoursData==null?0:intervCoursData}</p>
                                  </div>
                                  <div style={{ width: '30px', height: '30px' }}>
                                          <a onClick={() => {}/*handleLinkClick(`interventions/${compte_id}`)*/}> 
                                              <img src={openCom}
                                                width= '100%' 
                                                height='100%'
                                              />
                                          </a>
                                  </div>
                                </div>
                              <p style={{fontSize: 14, fontWeight: 'bold'}}>Interventions en cours</p>
                              <div style={{ width: '70px', height: '60px' }}>
                                          <img src={intervCours}
                                            width= '100%' 
                                            height='100%'
                                           /> 
                              </div>
                            </div>
                          </div>
                          <div class="col">
                          <div class="rounded-container">
                                <div class="flex-row">
                                  <div>
                                    <p style={{ fontSize: 20,marginBottom: -5}}>{intervClosData==null?0:intervClosData}</p>
                                  </div>
                                  <div style={{ width: '30px', height: '30px' }}>
                                          <a onClick={() => {}/*handleLinkClick(`interventions/${compte_id}`)*/}> 
                                              <img src={openCom}
                                                width= '100%' 
                                                height='100%'
                                              />
                                          </a>
                                  </div>
                                </div>
                              <p style={{fontSize: 14, fontWeight: 'bold'}}>Interventions clos</p>
                              <div style={{ width: '70px', height: '60px' }}>
                                          <img src={intervClos}
                                            width= '100%' 
                                            height='100%'
                                           /> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="rounded-container">
                                <div class="flex-row">
                                  <div>
                                    <p style={{ fontSize: 20,marginBottom: -5}}>{ /*soldeActuelData.toLocaleString('fr-FR')*/}</p>
                                  </div>
                                  <div style={{ width: '30px', height: '30px' }}>
                                          <a onClick={() => {}/*handleLinkClick(`configurationSysteme/${compte_id}/compte-trésorerie/${compte_id}`)*/}> 
                                              <img src={openCom}
                                                width= '100%' 
                                                height='100%'
                                              />
                                          </a>
                                  </div>
                                </div>
                              <p style={{fontSize: 14, fontWeight: 'bold'}}>Trésorerie</p>
                              <div style={{ width: '110px', height: '85px' }}>
                                          <img src={tresor}
                                            width= '100%' 
                                            height='100%'
                                           /> 
                              </div>
                            </div>
                    </div>
                 </div>
                 <div class="col-sm-4" style={{ backgroundColor: '#fcfcfc'}}>
                 <div class="container">
                        <div class="titre-text">
                          <p>Statistique technicien</p>
                        </div>
                        {userData!=null?
                        <div class="rounded-container">
                        <p style={{fontSize: 14, fontWeight: 'bold'}}>Technicien</p>
                        
                        {userData.map(utilisateur => (
                              <div class="flex-row2">
                              <div style={{ width: '30px', height: '30px' }}>
                                                <a onClick={() => {}/*handleLinkClick(`configurationSysteme/${compte_id}/Utilisateurs/${compte_id}`)*/}> 
                                                    <img src={user}
                                                      width= '100%' 
                                                      height='100%'
                                                    />
                                                </a>
                              </div>
                              <div style={{fontSize: 20}}>
                                {utilisateur.name}
                              </div>
                              <div style={{fontSize: 20, marginLeft: 'auto', marginBottom:3, backgroundColor: utilisateur.nbrInterv<5? '#FF204E':'#9BEC00', 
                                minWidth: 30, color: 'white', textAlign: 'center', borderRadius: 20}}>
                                {utilisateur.nbrInterv}
                              </div>
                        </div>
                          ))}
                        
                        </div>
                        :<p></p>}
                  </div>
                 </div>
                </div>
          </div>
        </div>
    );
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <img
                src={loadingImages[currentImage]}
                alt="Loading"
                style={{ width: 100, height: 100}}
              />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}