import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {CircularProgress,Box, Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Stack, Container, IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip } from '@mui/material';
import { Navigate, useNavigate,useParams } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://backend.bt-support.net/api/';
  var countData=1;




export default function ConfigurationCommerciaux() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [listCommerciaux, setListCommerciaux] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [nomCommer, setNomCommer] = useState('');
  const [prenomCommer, setPrenomCommer] = useState('');
  const [mailCommer, setMailCommer] = useState('');
  const [telCommer, setTelCommer] = useState('');
  const [adresseCommer, setAdresseCommer] = useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [backendErrorMessage, setBackendErrorMessage] = useState('');
  const { compte_id } = useParams();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  const request = async () => {
    try{
    await axios.get(`${BACKEND_URL}commerciaux/${compte_id}`)
      .then(({ data }) => {
        setListCommerciaux(data);
        console.log(listCommerciaux);
      }).catch((error) => {
        setBackendErrorMessage(error.response.data.error);
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setBackendErrorMessage('')
    setOpenDialog(false);
  };
  
  const ajoutCommerciaux = async (e) => {
    if(nomCommer==null || nomCommer=='')
      {
        setBackendErrorMessage('Veuillez entrer le nom')
      }
      else if(prenomCommer==null || prenomCommer=='')
      {
        setBackendErrorMessage('Veuillez entrer le prenom')
      }
      else if(adresseCommer==null || adresseCommer=='')
      {
        setBackendErrorMessage('Veuillez entrer l\'adresse ')
      }
      else if(telCommer==null || telCommer=='')
      {
        setBackendErrorMessage('Veuillez le téléphone')
      }
      else if(mailCommer==null || mailCommer=='')
      {
        setBackendErrorMessage('Veuillez entrer l\'e-mail ')
      }
      else{
    await axios.post(`${BACKEND_URL}commerciaux/create/${compte_id}`, {
                nomCommer: nomCommer,
                prenomCommer:prenomCommer,
                adresseCommer:adresseCommer,
                telCommer:telCommer,
                mailCommer: mailCommer
              }).then((data) =>{
                request();
                console.log(data)
                setOpenDialog(false);
              })
              .catch(error => {
                console.error('Erreur de requête :', error);
                setBackendErrorMessage(error.response.data.error)
    });
  }
  };

  const Deleterequest = async (approId) => {
    if (!window.confirm('Voulez-vous vraiment supprimer?')) {
      return;
    }    
    try {
      await axios.delete(`${BACKEND_URL}commerciaux/destroy/${approId}/${compte_id}`);
      console.log(`Approvisionnement avec l'ID ${approId} supprimé avec succès`);
      // Ajoutez ici la logique supplémentaire après la suppression
      setOpen(true);
      request()
      window.alert('Element supprimer avec succès')
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'approvisionnement', error);
      window.alert(error.response.data.error)
      // Ajoutez ici la gestion des erreurs
    }
  }

  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
  };

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
        
          const tableRows = listCommerciaux.map((row, index) => (
            `<tr>
              <td>${index + 1}</td>
              <td>${row.nomCommer}</td>
              <td>${row.adresseCommer}</td>
              <td>${row.telCommer}</td>
              <td>${row.mailCommer}</td>
              
            </tr>`
          )).join('');
          
          // Utilisez la chaîne HTML générée pour construire le document HTML
          const htmlContent = `
            <html lang="fr">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Tableau des commerciaux</title>                
                <style>
                  body { font-family: Arial, sans-serif; }
                  table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                  th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                  th { background-color: #f2f2f2; }
                </style>
              </head>
              <body>
                <h2>Tableau des commerciaux</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Numéro</th>
                      <th>Nom</th>
                      <th>Adresse</th>
                      <th>Contact</th>
                      <th>Mail</th>
                     </tr>
                  </thead>
                  <tbody>
                    ${tableRows}
                  </tbody>
                </table>
              </body>
            </html>`;
          
          // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
          printWindow.document.write(htmlContent);
          printWindow.document.close();
          printWindow.print();
  };

  useEffect(() => {
    try {
      request();
      
    } catch (error) {
      console.log(error)
    }
    const timer = setTimeout(() => {
      // Mettez à jour l'état pour arrêter le chargement après 3 secondes
      setLoading(false);
    }, 1000); // 3000 millisecondes = 3 secondes

    // Nettoyez le timer lorsque le composant est démonté
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
    }, 100);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
      window.removeEventListener('resize', handleResize);
  };

  }, []);

  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  if(!loading) {
  return (
    <div style={{ backgroundColor: '#fcfcfc', height: '100vh', width: '100%', }}>
      <Nav compte_id={compte_id}/>
      <Stack direction={'row'}
        alignItems='center'
        spacing={{}}
        sx={{ margin: 3 }}>
        <Button variant="contained" style={{ minWidth: 100, marginRight: 'auto' }} startIcon={<ArrowBackIcon />} onClick={() => { handleLinkClick(`configurationStock/${compte_id}`) }} >
          RETOUR
        </Button>
        <Tooltip title="Imprimer">
          <IconButton onClick={() => handlePrint()} >
            <PrintIcon style={{ color: 'blue' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Exporter en PDF">
          <IconButton onClick={() => handlePrint()} >
            <PictureAsPdfIcon style={{ color: 'red' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Exporter en EXCEL">
          <IconButton onClick={() => handlePrint()} >
            <TableChartIcon style={{ color: 'green' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Exporter en CSV">
          <IconButton onClick={() => handlePrint()} >
            <DescriptionIcon style={{ color: '#2D9596' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copier">
          <IconButton onClick={() => handlePrint()} >
            <FileCopyIcon style={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction={'column'}
        alignItems='center'
        sx={{
          marginTop: 3,
          marginLeft: 2,
          marginRight: 2,
          borderRadius: 10,
          minWidth:windowWidth-150,
          boxShadow: '0px 0px 5px #FF5200',
          backgroundColor: 'white'
        }}>
        <Stack sx={{ fontWeight: 600, fontSize: 20, margin: 2 }}>
          Liste des commerciaux
        </Stack>
        <Stack>
          {windowWidth>700?
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: windowWidth-200  }} aria-label="simple table">
              <TableHead style={{ backgroundColor: '#FF5200', }}>
                <TableRow>
                  <TableCell align="center" style={{ color: 'white', fontWeight: 600, fontSize: 20 }} colSpan={6}>Commerciale</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Numéro</TableCell>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Nom</TableCell>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Adresse</TableCell>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Contact</TableCell>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >E-mail</TableCell>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Action</TableCell>

                </TableRow>
                {listCommerciaux.map((row, index) => (
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 600 }}>{index + 1}</TableCell>
                    <TableCell align="center" style={{ fontWeight: 600 }}>{row.nomCommer}</TableCell>
                    <TableCell align="center" style={{ fontWeight: 600 }}>{row.adresseCommer}</TableCell>
                    <TableCell align="center" style={{ fontWeight: 600 }}>{row.telCommer}</TableCell>
                    <TableCell align="center" style={{ fontWeight: 600 }}>{row.mailCommer}</TableCell>
                    <TableCell align="center" style={{ fontWeight: 600 }}>
                      <Tooltip title='Modifier'>
                        <IconButton style={{ color: '#40A2E3' }} onClick={() => {}} >
                          <EditIcon />
                        </IconButton>
                        </Tooltip>
                      <Tooltip title='Voir'>
                        <IconButton style={{ color: 'grey' }} disabled onClick={() => { navigate(`/afficher-approvisionement/${row.id}`) }} >
                          <ArrowForwardIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Supprimer'>
                        <IconButton style={{ color: '#D24545' }} onClick={() => { Deleterequest(row.id) }} >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          :
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: windowWidth-200  }} aria-label="simple table">
              <TableHead style={{ backgroundColor: '#FF5200', }}>
                <TableRow>
                  <TableCell align="center" style={{ color: 'white', fontWeight: 600, fontSize: 20 }} colSpan={6}>Commerciale</TableCell>
                </TableRow>
              </TableHead>
              {listCommerciaux.map((row, index) => (
              <TableBody>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#FED8B1' }} colSpan={2}>Nom</TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600 }} colSpan={2}>{row.nomCommer}</TableCell>
                    
                  </TableRow>
                  <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Adresse</TableCell>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Contact</TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600 }}>{row.adresseCommer}</TableCell>
                    <TableCell align="center" style={{ fontWeight: 600 }}>{row.telCommer}</TableCell>                    
                  </TableRow>
                  <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }}colSpan={2} >E-mail</TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600 }} colSpan={2}>{row.mailCommer}</TableCell>                    
                  </TableRow>
                  <TableRow>
                  <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} colSpan={2}>Action</TableCell>
                </TableRow>                
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 600 }} colSpan={2}>
                      <Tooltip title='Modifier'>
                        <IconButton style={{ color: '#40A2E3' }} onClick={() => {}} >
                          <EditIcon />
                        </IconButton>
                        </Tooltip>
                      <Tooltip title='Voir'>
                        <IconButton style={{ color: 'grey' }} disabled onClick={() => { navigate(`/afficher-approvisionement/${row.id}`) }} >
                          <ArrowForwardIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Supprimer'>
                        <IconButton style={{ color: '#D24545' }} onClick={() => { Deleterequest(row.id) }} >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                
              </TableBody>
              ))}
            </Table>
          </TableContainer>}

        </Stack>
        <Button variant="contained" style={{ margin: 20 }} startIcon={<AddIcon />} onClick={() => handleClickOpenDialog()} >
          Ajouter
        </Button>
      </Stack>
      <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du commerciale"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Nom commerciale'
                                value={nomCommer}
                                onChange={(e)=> {
                                    setNomCommer(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Prénom commerciale'
                                value={prenomCommer}
                                onChange={(e)=> {
                                    setPrenomCommer(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='tel' 
                                label='Téléphone commerciale'
                                value={telCommer}
                                onChange={(e)=> {
                                    setTelCommer(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Adresse commerciale'
                                value={adresseCommer}
                                onChange={(e)=> {
                                    setAdresseCommer(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='mail' 
                                label='E-mail commerciale'
                                value={mailCommer}
                                onChange={(e)=> {
                                    setMailCommer(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutCommerciaux()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>
    </div>
  );
}
else
{
    return(
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // 100% de la hauteur de la vue
        }}
      >
        <Stack direction={'column'} alignItems={'center'} spacing={1}>
            <CircularProgress
            />
            <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
        </Stack>
      </Box>
    );
}
}