import React, { useState,useEffect } from 'react';
import axios from 'axios';
import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import 'bootstrap/dist/css/bootstrap.css';
import it_person from './images/it_person.png';
import '../configuration/config';
import * as ReactDOM from "react-dom/client";
import {Box, CircularProgress ,Stack, Tooltip, Container} from '@mui/material';
import {createBrowserRouter, Route, RouterProvider, useNavigate } from "react-router-dom";
import Lottie from 'lottie-react';
import techni from './images/lottie/techni.json'
import hibou from './images/lottie/hibou.json'

var BACKEND_URL = 'https://backend.bt-support.net/api/';

gsap.registerPlugin(TextPlugin);


export default function Connexion() {

    const [load,setLoad]= useState();
    const [userData, setUserData] = useState({
        email: '',
        password: ''
        // Ajoutez d'autres champs selon vos besoins
    });
    const[dataU, setDataU] = useState('')

    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    //const history = useHistory();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(()=>{
        const ref = document.querySelector(".anime");
        
        gsap.timeline().to(ref, {
          duration: 1,
          text: "Bienvenue",
          color:'#D20062',          
          fontFamily:'monospace',
          ease: "expo.inOut",
          delay: 2,
          
        }).to(ref, {
          duration: 1,
          text: "Connectez-vous",
          color:'#3DC2EC',          
          fontFamily:'cursive',
          ease: "expo.inOut",
          delay: 2,
          
        });
    })
    const handleSubmit = async (e) => {
        setLoad(true);
        try {
            e.preventDefault();
            await axios.post(`${BACKEND_URL}login`, userData)
                .then(({data}) => {
                    console.log(data);
                    setDataU(data)
                    navigate(`/choixCompte/${data.id}`);
                }).catch ((error) =>{
                    setLoad(false);
                if (error.response) {
                    // Le serveur a renvoyé une réponse avec un code d'état qui ne se situe pas dans la plage 2xx
                    console.log('Erreur du serveur:66666666', error);
                    setBackendErrorMessage(error.response.data.error);
                } else if (error.request) {
                    // La requête a été faite mais aucune réponse n'a été reçue
                    console.log('Aucune réponse reçue du serveur:', error.request);
                    setBackendErrorMessage('Aucune réponse reçue du serveur.');
                } else {
                    // Autre type d'erreur
                    console.log('Erreur de la requête:', error.message);
                    setBackendErrorMessage('Une erreur est survenue lors de la requête.');
                }
            });
            
        } catch (error) {
            console.log(error)
        }        
    };
    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    {/* Colonne de gauche (Formulaire) */}
                    <div class="container-fluid bg-whitw vh-100 d-flex justify-content-center align-items-center">
                    <div class="p-4 rounded shadow">
                        <h1 class="anime">BT-Support</h1>
                        <Lottie animationData={techni} loop style={{
                            height:200,
                            width:200
                        }}/>
                        <form method='POST' onSubmit={handleSubmit}>
                            <div class="mb-3">
                                <label for="name" class="form-label">E-mail utilisateur</label>
                                <input type="text" class="form-control" id="name" placeholder="Entrer votre mail" name="email" value={userData.email}  onChange={handleChange} />
                            </div>
                            <div class="mb-3">
                                <label for="password" class="form-label">Mot de passe</label>
                                <input type="password" class="form-control" id="password" placeholder="Entrer votre mot de passe" name="password" value={userData.password} onChange={handleChange} />
                            </div>
                            <button type="submit" class="btn btn-info text-white">Se connecter</button>
                        </form>
                        <p style={{
                            fontSize:15,
                            fontWeight:'bold',
                            fontStyle:'italic',
                            marginTop:15,
                            color:'blueviolet'
                        }}>BT-Support vous souhaite de joyeuses fêtes de fin d'année ! 🎉✨</p>
                        {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                    </div>
                    
                    </div>
                </div>
                <div class="col-sm-6">
                    {/* Colonne de droite (Image) */}
                    <div class="container-fluid bg-info vh-100 d-flex justify-content-center align-items-center" style={{ borderRadius: '20px' , }}>
                    {/* Contenu de votre conteneur ici */}
                        <div style={{ width: '250px', height: '210px' }}>
                        <Lottie animationData={hibou} loop style={{
                            height:'100%',
                            width:'100%'
                        }}/>
                        </div>
                    </div>
                </div>
            </div>
    </div>
);
}
/*else
{
    return(
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // 100% de la hauteur de la vue
        }}
      >
        <Stack direction={'column'} alignItems={'center'} spacing={1}>
            <CircularProgress />
            <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
        </Stack>
      </Box>
    );
}
}*/
