import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {CircularProgress,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete} from '@mui/material';
import { Navigate, useNavigate,useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import Fuse from 'fuse.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import * as XLSX from 'xlsx'
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://backend.bt-support.net/api/';
  var countData=1;

export default function ConfigurationProduit() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listProduit, setListProduit] = useState([]);
    const [listProduitModif, setListProduitModif] = useState([]);
    const [listMarque, setListMarque] = useState([]);
    const [listModele, setListModele] = useState([]);
    const [listTypeProd, setListTypeProd] = useState([]);
    const [listTypeProd2, setListTypeProd2] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [prod_id, setProd_id] = useState('');
    const [modele_id, setModele_id] = useState(0);
    const [typeProd_id, setTypeProd_id] = useState(0);
    const [nomProd, setNomProd] = useState('');
    const [modeleProduit, setmodeleProduit] = useState('');
    const [marqueProduit, setmarqueProduit] = useState('');
    const [prixVente, setprixVente] = useState('');
    const [typeProduit, settypeProduit] = useState('');
    const [typeP, setTypeP] = useState('');
    const [nomModele, setNomModele] = useState('');
    const [typeProduit2, settypeProduit2] = useState('');

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogModifier, setOpenDialogModifier] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const { compte_id } = useParams();

    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listProduit.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listProduit.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}produit/${compte_id}`)
        .then(({data}) => {
            setListProduit(data);
            console.log(listProduit);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}modeleProduit/${compte_id}`)
        .then(({data}) => {
            setListModele(data);
            console.log(listModele);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}marqueProduit/${compte_id}`)
        .then(({data}) => {
            setListMarque(data);
            console.log(listMarque);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}typeProduit2/${compte_id}`)
        .then(({data}) => {
            setListTypeProd(data);
            console.log(listTypeProd);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}typeProduit/${compte_id}`)
        .then(({data}) => {
            setListTypeProd2(data);
            console.log(listTypeProd2);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setBackendErrorMessage('')
      setOpenDialog(false);
    };
    
    const ajoutProduit = async (e) => {
      if(nomProd==null || nomProd=='')
      {
        setBackendErrorMessage('Veuillez entrer le libellé du produit')
      }      
      else if(modeleProduit==null || modeleProduit=='')
      {
        setBackendErrorMessage('Veuillez entrer le modèle du produit')
      }
      else if(prixVente==null || prixVente=='')
      {
        setBackendErrorMessage('Veuillez entrer le prix de vente')
      }
      else if(typeProduit==null || typeProduit=='')
      {
        setBackendErrorMessage('Veuillez entrer le type de produit')
      }
      else if(typeProduit2==null || typeProduit2=='')
      {
        setBackendErrorMessage('Veuillez entrer le type de produit')
      }
      else{
      let nomProdVeritable = `${typeProduit} ${nomProd} ${modeleProduit}`;
      await axios.post(`${BACKEND_URL}produit/create/${compte_id}`, {
                      nomProd: nomProdVeritable,
                      prixVente: prixVente,
                      typeProd:typeProduit2,
                      modele_id:modele_id,
                      typeproduit_id:typeProd_id
                }).then((data) =>{
                  request();
                  console.log(data)
                  handleCloseDialog();
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
                  });    
                }       
    };


    const handleClickOpenDialogModifier = () => {      
      setOpenDialogModifier(true);
    };
  
    const handleCloseDialogModifier = () => {
      setBackendErrorMessage('')
      setNomProd('')
      setprixVente('')
      setNomModele('')
      setTypeP('')
      settypeProduit('')
      setOpenDialogModifier(false);
    };

    const modifierProduit = async (e) => {   
      let nomProdVeritable = `${typeP} ${nomProd} ${modeleProduit}`;
      await axios.put(`${BACKEND_URL}produit/update/${prod_id}/${compte_id}`, {
                          nomProd: nomProdVeritable,
                          prixVente: prixVente,
                          typeProd:typeProduit,
                          nomModele:nomModele,
                          typeProduit:typeP
                }).then((data) =>{
                  request();
                  console.log(data)
                  handleCloseDialogModifier();
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
                  });           
    };

    const Deleterequest = async (approId) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }      
        try {
          await axios.delete(`${BACKEND_URL}produit/destroy/${approId}/${compte_id}`);
          console.log(`Approvisionnement avec l'ID ${approId} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          window.alert('Element supprimer avec succès')
          request()
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };


    const [searchQuery, setSearchQuery] = useState('');
    // Configuration de Fuse.js
const fuseOptions = {
 keys: ['nomProd'], // Les champs à rechercher
 includeScore: true,
};

// Initialiser un nouvel objet Fuse avec les options
const fuse = new Fuse(listProduit, fuseOptions);

// Fonction pour gérer le changement de la recherche
const handleSearchChange = (e) => {
 const query = e.target.value;
 setSearchQuery(query);

 // Effectuer la recherche et mettre à jour les résultats
 const results = fuse.search(query);

 if (query==''){
   //setApproData(results.map((result) => result.item));
   request()
 }
 else{
   setListProduit(results.map((result) => result.item));
 }

};


    
        const handlePrint = () => {
          const printWindow = window.open('', '_blank');
        
          const tableRows = listProduit.map((row, index) => (
            `<tr>
              <td>${index + 1}</td>
              <td>${row.nomProd}</td>
              <td>${row.prixVente}</td>
              <td>${row.typeProd}</td>
            </tr>`
          )).join('');
          
          // Utilisez la chaîne HTML générée pour construire le document HTML
          const htmlContent = `
            <html lang="fr">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">    
                <title>Tableau des produits</title>              
                <style>
                  body { font-family: Arial, sans-serif; }
                  table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                  th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                  th { background-color: #f2f2f2; }
                </style>
              </head>
              <body>
                <h2>Tableau des produits</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Numéro</th>
                      <th>Nom produit</th>
                      <th>Prix vente</th>
                      <th>Type produit</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${tableRows}
                  </tbody>
                </table>
              </body>
            </html>`;
          
          // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
          printWindow.document.write(htmlContent);
          printWindow.document.close();
          printWindow.print();
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const timer = setTimeout(() => {
              // Mettez à jour l'état pour arrêter le chargement après 3 secondes
              setLoading(false);
            }, 3000); // 3000 millisecondes = 3 secondes
        
            // Nettoyez le timer lorsque le composant est démonté
            const interval = setInterval(() => {
              setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
            }, 300);

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
  
          window.addEventListener('resize', handleResize);

            return () => {
              clearTimeout(timer);
              clearInterval(interval);
              window.removeEventListener('resize', handleResize);
          };
          
          }, []);

          const [loading, setLoading] = useState(true);
          const [currentImage, setCurrentImage] = useState(0);
          const navigate = useNavigate();



          const handleExportExcel = () => {
            var wb = XLSX.utils.book_new();
            var ws = XLSX.utils.json_to_sheet(listProduit);
        
            //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });
        
            // Mettre la première ligne en gras
            //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });
        
            XLSX.utils.book_append_sheet(wb, ws , 'Produits');
        
            XLSX.writeFile(wb , 'Liste des produits.xlsx');
        
          }
        

  if(!loading) {
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id}/>
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`configurationStock/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handleExportExcel()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => {}} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => {}} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: 2, 
                      marginRight: 2, 
                      borderRadius: 5, 
                      minWidth:windowWidth-150,
                      boxShadow: '0px 0px 5px #9BCF53',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des produits
                  </Stack>
                  
                    <div></div>
                  <Stack>
                  <TextField type="text" 
                          value={searchQuery} 
                          style={{marginBottom: 15, 
                            marginRight:'auto',
                           marginLeft:8}}
                          onChange={handleSearchChange} 
                          placeholder="Rechercher...">
                    </TextField>
                    {windowWidth>700?
                    <TableContainer component={Paper}>
                            <Table sx={{  minWidth:windowWidth-200, }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#9BCF53',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={5}>Produits</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Nom</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Prix vente</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Type produit</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>
                                            
                                    </TableRow>
                                        {data.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomProd}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.prixVente}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.typeProd}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>                                                
                                                    <Tooltip title='Modifier'>
                                                        <IconButton style={{ color: '#40A2E3' }}  onClick={()=>{
                                                          console.log(row.id)
                                                          setProd_id(row.id);
                                                           axios.get(`${BACKEND_URL}produit/show/${row.id}/${compte_id}`)
                                                          .then(({data}) => {
                                                              setListProduitModif(data);
                                                              console.log(listProduitModif);
                                                              setNomProd(data.nomProd)
                                                              setprixVente(data.prixVente)
                                                              setNomModele(data.nomModeleProd)
                                                              setTypeP(data.typeP)
                                                              settypeProduit(data.typeProd)
                                                              handleClickOpenDialogModifier()
                                                          }).catch ((error) =>
                                                          {
                                                              setBackendErrorMessage(error.response.data.error);
                                                          });
                                                          
                                                        }} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    {/*<Tooltip title='Voir'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{
                                                          //navigate(`/afficher-approvisionement/${row.id}`)
                                                          }} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                        </Tooltip> */}                         
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                 
                                            </TableCell>
                                                    </TableRow>
                                                      ))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{  minWidth:windowWidth-200, }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#9BCF53',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={5}>Produits</TableCell>
                                    </TableRow>
                                </TableHead>
                                {data.map((row, index) => (
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Nom</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600}}colSpan={2}>{row.nomProd}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Prix vente</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Type produit</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600}}>{row.prixVente}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.typeProd}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}colSpan={2} >Action</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>                                                
                                                    <Tooltip title='Modifier'>
                                                        <IconButton style={{ color: '#40A2E3' }}  onClick={()=>{
                                                          console.log(row.id)
                                                          setProd_id(row.id);
                                                           axios.get(`${BACKEND_URL}produit/show/${row.id}/${compte_id}`)
                                                          .then(({data}) => {
                                                              setListProduitModif(data);
                                                              console.log(listProduitModif);
                                                              setNomProd(data.nomProd)
                                                              setprixVente(data.prixVente)
                                                              setNomModele(data.nomModeleProd)
                                                              setTypeP(data.typeP)
                                                              settypeProduit(data.typeProd)
                                                              handleClickOpenDialogModifier()
                                                          }).catch ((error) =>
                                                          {
                                                              setBackendErrorMessage(error.response.data.error);
                                                          });
                                                          
                                                        }} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    {/*<Tooltip title='Voir'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{
                                                          //navigate(`/afficher-approvisionement/${row.id}`)
                                                          }} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                        </Tooltip> */}                         
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                 
                                            </TableCell>
                                                    </TableRow>
                                                      
                                                </TableBody>
                                                ))}
                            </Table>
                    </TableContainer>}
                    <div class={windowWidth>700?"flex-row":"flex-column"}>
                                    <nav > 
                                        <ul className='pagination'>
                                            <li className='page-item'>
                                              <a href='#' className='page-link'
                                                onClick={prePage}>Préc</a>
                                            </li>
                                            {
                                              getPageNumbersToShow().map((n,i) =>
                                              (
                                                <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                                  <a href='#' className='page-link'
                                                  onClick={() => changeCPage(n)}>{n}</a>
                                                </li>
                                              )
                                              )
                                            }
                                            <li className='page-item'>
                                              <a href='#' className='page-link'
                                                onClick={nextPage}>Suiv</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div>
                                      <h5>Total produits:  {listProduit.length}</h5> 
                                    </div>
                                </div>
                  </Stack>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
                    Ajouter                      
            </Button> 
            </Stack>         
            <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du produit"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Libéllé produit'
                                value={nomProd}
                                style={{minWidth:515}}
                                onChange={(e)=> {
                                    setNomProd(e.target.value)
                                }}
                            ></TextField>
                            
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            
                            <Autocomplete
                              id="combo-box-demo"
                              options={listTypeProd}
                              getOptionLabel={(option) => option.typeP}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={typeProduit.value}
                              onChange={(event, newValue) => {
                                settypeProduit(newValue ? newValue.typeP:'');
                                console.log(newValue ? newValue.id:'');
                                setTypeProd_id(newValue ? newValue.id:'');
                                console.log(newValue ? newValue.typeP:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un type"  />
                              )}
                            />
                            <Autocomplete
                              id="combo-box-demo"
                              options={listModele}
                              getOptionLabel={(option) => `${option.nomMarqueProd} ${option.nomModeleProd}`}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={modeleProduit.value}
                              onChange={(event, newValue) => {
                                setmodeleProduit(newValue ? `${newValue.nomMarqueProd} ${newValue.nomModeleProd} `:'');
                                console.log(newValue ? newValue.id:'');
                                setModele_id(newValue ? newValue.id:'')
                                console.log(newValue ? newValue.nomModeleProd:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un modèle"  />
                              )}
                            />
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >                            
                            <TextField type='number' 
                                label='Prix de vente'
                                value={prixVente}
                                style={{minWidth:250}}
                                onChange={(e)=> {
                                    setprixVente(e.target.value)
                                }}
                            ></TextField>
                            <Autocomplete
                              id="combo-box-demo"
                              options={listTypeProd2}
                              getOptionLabel={(option) => option.nomTypeProd}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={typeProduit.value}
                              onChange={(event, newValue) => {
                                settypeProduit2(newValue ? newValue.nomTypeProd:'');
                                console.log(newValue ? newValue.nomTypeProd:'');
                                // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Equipement ou pièce ?"  />
                              )}
                            />
                          </Stack>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutProduit()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  fullScreen={fullScreen}
                  open={openDialogModifier}
                  onClose={handleCloseDialogModifier}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Modification du produit"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Stack direction={'column'}>
                                <label style={{fontWeight: 600}}>Libéllé produit</label>
                            <TextField type='text'                                
                                defaultValue={nomProd}
                                style={{minWidth:515}}
                                onChange={(e)=> {
                                    setNomProd(e.target.value)
                                }}
                            ></TextField>
                            </Stack>
                            
                            
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          > 
                          <Stack direction={'column'}>
                                <label style={{fontWeight: 600}}>Sélectionnez un modèle</label>
                            <Autocomplete
                              id="combo-box-demo"
                              options={listModele}
                              getOptionLabel={(option) => `${option.nomMarqueProd} ${option.nomModeleProd}`}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              //defaultValue={nomModele}
                              onChange={(event, newValue) => {
                                setmodeleProduit(newValue ? `${newValue.nomMarqueProd} ${newValue.nomModeleProd} `:'');
                                console.log(newValue ? newValue.nomModeleProd:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label={nomModele}  />
                              )}
                            />   
                            </Stack>     
                            <Stack direction={'column'}>
                                <label style={{fontWeight: 600}}>Sélectionnez un type</label>                   
                            <Autocomplete
                              id="combo-box-demo"
                              options={listTypeProd}
                              getOptionLabel={(option) => option.typeP}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              //defaultValue={'jsjsjs'}
                              //value={typeP.value}
                              onChange={(event, newValue) => {
                                setTypeP(newValue ? newValue.typeP:'');
                                console.log(newValue ? newValue.typeP:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label={typeP}  />
                              )}
                            />
                            </Stack>
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >    
                          <Stack direction={'column'}>
                                <label style={{fontWeight: 600}}>Equipement ou pièce?</label>
                          <Autocomplete
                              id="combo-box-demo"
                              options={listTypeProd2}
                              getOptionLabel={(option) => option.nomTypeProd}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              //defaultValue={typeProduit}
                              onChange={(event, newValue) => {
                                settypeProduit(newValue ? newValue.nomTypeProd:'');
                                console.log(newValue ? newValue.nomTypeProd:'');
                                // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label={typeProduit}  />
                              )}
                            />   
                            </Stack>  
                            <Stack direction={'column'}>
                                <label style={{fontWeight: 600}}>Prix de vente</label>                   
                            <TextField type='number'
                                value={prixVente}
                                style={{minWidth:250}}
                                onChange={(e)=> {
                                    setprixVente(e.target.value)
                                }}
                            ></TextField>
                            </Stack>
                          </Stack>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {modifierProduit()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogModifier} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>  
        </div>
    );
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress
              />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }

  
  function nextPage(){
    if(firstIndex+5 < listProduit.length)
    {
      setCurrentPage(currentPage + 1);
      countData=countData+10;
    }
  }

  function prePage(){
    if(firstIndex-1>0)
    {
      setCurrentPage(currentPage - 1);
      countData=countData-10;
      console.log(countData)
    }
  }

  function changeCPage(id){
    setCurrentPage(id);
    countData = ((id*10)-9)
  }
}