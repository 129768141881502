import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncIcon from '@mui/icons-material/Sync';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import ScheduleIcon from '@mui/icons-material/Schedule';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import m2agroup from '../images/m2a.png';


var BACKEND_URL = 'https://backend.bt-support.net/api/';
export default function AfficherClient() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [client, setclient] = useState([]);
    const [listTypeClient, setListTypeClient] = useState([]);
    const [nomClient, setnomClient] = useState('');
    const [typeClient, settypeClient] = useState('');
    const [codeClient, setcodeClient] = useState('');
    const [adresseClient, setadresseClient] = useState('');
    const [telClient, settelClient] = useState('');
    const [mailClient, setMailClient] = useState('');
    const [boitePostale, setBP] = useState('');
    const [rccm, setRccm] = useState('');
    const [regimeNormale, setRegimeNormale] = useState('');
    const [numeroIFU, setNumeroIFU] = useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [focused1, setFocused1] = useState(false);
    const { compte_id } = useParams();
   
    const { client_id} = useParams();


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}client/show/${client_id}/${compte_id}`)
        .then(({data}) => {
            setclient(data);
            console.log(client);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}typeClient/${compte_id}`)
        .then(({data}) => {
            setListTypeClient(data);
            console.log(listTypeClient);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setBackendErrorMessage('')
      setOpenDialog(false);
    };

    const modifierClient = async (e) => {
      if(nomClient==null || nomClient=='')
      {
        setBackendErrorMessage('Veuillez entrer le nom du client')
      }
      else if(typeClient==null || typeClient=='')
      {
        setBackendErrorMessage('Veuillez entrer le type de client')
      }
      else if(codeClient==null || codeClient=='')
      {
        setBackendErrorMessage('Veuillez entrer le code du client')
      }
      else if(telClient==null || telClient=='')
      {
        setBackendErrorMessage('Veuillez entrer le contact du client')
      }
      else if(mailClient==null || mailClient=='')
      {
        setBackendErrorMessage('Veuillez entrer l\'e-mail du client')
      }
      else{
      await axios.put(`${BACKEND_URL}client/update/${client_id}/${compte_id}`, {
                    nomClient: nomClient,
                    typeClient:typeClient,
                    codeClient:codeClient,
                    telClient:telClient,
                    adresseClient:adresseClient,
                    mailClient:mailClient,
                    boitePostale: boitePostale,
                    rccm: rccm,
                    numeroIFU: numeroIFU,
                    regimeNormale: regimeNormale,
                }).then((data) =>{
                  request();
                  console.log(data)
                  setOpenDialog(false);
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
      });
    }
    };

    

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };
    
    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        
          const tableRows = 
            `
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Client</td>
              <td align="center" style={{fontWeight: 600}}>${client.nomClient}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Type client</td>
              <td align="center" style={{fontWeight: 600}}>${client.nomTypeClient}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Code</td>
              <td align="center" style={{fontWeight: 600}}>${client.codeClient}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Boite postale</td>
              <td align="center" style={{fontWeight: 600}}>${client.boitePostale ==null ? '' : client.boitePostale}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Adresse</td>
              <td align="center" style={{fontWeight: 600}}>${client.adresseClient ==null ? '' : client.telClient}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Téléphone</td>
              <td align="center" style={{fontWeight: 600}}>${client.telClient}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>E-mail</td>
              <td align="center" style={{fontWeight: 600}}>${client.mailClient ==null ? '' : client.mailClient}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>RCCM</td>
              <td align="center" style={{fontWeight: 600}}>${client.rccm==null ? '' : client.rccm}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Numéro IFU</td>
              <td align="center" style={{fontWeight: 600}}>${client.numeroIFU == null ? '' : client.numeroIFU}</td>
            </tr>
            <tr>
              <td align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Régime normale</td>
              <td align="center" style={{fontWeight: 600}}>${client.regimeNormale == null || 0 ? '' : client.regimeNormale}</td>
            </tr>

            <!-- Ajoutez les autres lignes ici -->
          `;
          
          // Utilisez la chaîne HTML générée pour construire le document HTML
          const htmlContent = `
            <html lang="fr">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Détails du client ${client.nomClient}</title>                
                <style>
                  body { font-family: Arial, sans-serif; }
                  table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                  th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                  th { background-color: #f2f2f2; }
                </style>
              </head>
              <body>
                <h2>Détails du client ${client.nomClient}</h2>
                <table>
                  <thead>
                    <tr>
                     </tr>
                  </thead>
                  <tbody>
                    ${tableRows}
                  </tbody>
                </table>
              </body>
            </html>`;
          
          // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
          printWindow.document.write(htmlContent);
          printWindow.document.close();
          printWindow.print();
      };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
  
          window.addEventListener('resize', handleResize);

            return()=>{
              window.removeEventListener('resize', handleResize);
            };
          }, []);


  const navigate = useNavigate();

    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`clients/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handlePrint()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => handlePrint()} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => handlePrint()} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: windowWidth>700?10:2, 
                      marginRight: windowWidth>700?10:2, 
                      borderRadius: 5,
                      paddingTop: 5,
                      paddingBottom: 5,
                      boxShadow: '0px 0px 5px #0802A3',
                      backgroundColor:'white',
                      minWidth: windowWidth>700?600:200}}>                  
                  <Stack>
                    {windowWidth>700?                    
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0802A3',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Details du client {client.nomClient}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>                                    
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Client</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{client.nomClient}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5', }}>Type de Client</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0, color:'red'}}>{client.nomTypeClient}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Code</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{client.codeClient}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Boite postale</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{client.boitePostale == null ? '' : client.boitePostale}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Adresse</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{client.adresseClient  == null ? '' : client.adresseClient}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Téléphone</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{client.telClient  == null ? '' : client.telClient}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>E-mail</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{client.mailClient  == null ? '' : client.mailClient}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>RCCM</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{client.rccm == null ? '' : client.rccm}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600 , backgroundColor: '#F6F5F5'}}>Numéro IFU</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{client.numeroIFU  == null ? '' : client.numeroIFU}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600 , backgroundColor: '#F6F5F5'}}>Régime normale</TableCell>
                                            <TableCell align="center" style={{fontWeight: 0}}>{client.regimeNormale  == null ? '' : client.regimeNormale}</TableCell>
                                    </TableRow>                                   
                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 150, maxWidth: 500 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0802A3',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Details du client {client.nomClient}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>                                    
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Client</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                          <TableCell align="center" style={{}} colSpan={2}>{client.nomClient}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Type de Client</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Code</TableCell>
                                    </TableRow>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600, color:'red'}}>{client.nomTypeClient}</TableCell>
                                            <TableCell align="center" style={{}}>{client.codeClient}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Boite postale</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Adresse</TableCell>
                                    </TableRow>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{}}>{client.boitePostale == null ? '' : client.boitePostale}</TableCell>
                                            <TableCell align="center" style={{}}>{client.adresseClient  == null ? '' : client.adresseClient}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>Téléphone</TableCell>     
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>E-mail</TableCell>                                       
                                    </TableRow>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{}}>{client.telClient  == null ? '' : client.telClient}</TableCell>
                                            <TableCell align="center" style={{}}>{client.mailClient  == null ? '' : client.mailClient}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}}>RCCM</TableCell>   
                                            <TableCell align="center" style={{fontWeight: 600 , backgroundColor: '#F6F5F5'}}>Numéro IFU</TableCell>                                         
                                    </TableRow>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{}}>{client.rccm == null ? '' : client.rccm}</TableCell>
                                            <TableCell align="center" style={{}}>{client.numeroIFU  == null ? '' : client.numeroIFU}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600 , backgroundColor: '#F6F5F5'}} colSpan={2}>Régime normale</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{fontWeight: 0}} colSpan={2}>{client.regimeNormale  == null ? '' : client.regimeNormale}</TableCell>
                                    </TableRow>                                   
                                </TableBody>
                            </Table>
                    </TableContainer>
                    }
                   
                  </Stack> 
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => {
                    setnomClient(client.nomClient)
                    settypeClient(client.nomTypeClient)
                    setcodeClient(client.codeClient)
                    setBP(client.boitePostale)
                    setadresseClient(client.adresseClient)
                    settelClient(client.telClient)
                    setMailClient(client.mailClient)
                    setRccm(client.rccm)
                    setNumeroIFU(client.numeroIFU)
                    setRegimeNormale(client.regimeNormale)
                    handleClickOpenDialog()
                    }} >
                    Modifier                      
            </Button>                
            </Stack>

            <Dialog
                  //fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du client"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Nom client'
                                value={nomClient}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                    setnomClient(e.target.value)
                                }}
                            ></TextField>
                            <Autocomplete
                              id="combo-box-demo"
                              options={listTypeClient}
                              getOptionLabel={(option) => option.nomTypeClient}
                              size='medium'
                              style={{minWidth: windowWidth>700?210:100}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={typeClient.value}
                              onChange={(event, newValue) => {
                                settypeClient(newValue ? newValue.nomTypeClient: typeClient);
                                console.log(newValue ? newValue.nomTypeClient:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label= {focused1 ?"Sélectionnez un type": typeClient} 
                                onFocus={() => setFocused1(true)}
                                onBlur={() => setFocused1(false)}
                                  />
                              )}
                            />
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Code Client'
                                value={codeClient}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                  setcodeClient(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Adresse client'
                                value={adresseClient}
                                onChange={(e)=> {
                                    setadresseClient(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Téléphone client'
                                value={telClient}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                    settelClient(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Mail client'
                                value={mailClient}
                                onChange={(e)=> {
                                    setMailClient(e.target.value)
                                }}
                            ></TextField>
                            
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='RCCM'
                                value={rccm}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                    setRccm(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Boite postale'
                                value={boitePostale}
                                onChange={(e)=> {
                                    setBP(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Régime Normale'
                                value={regimeNormale}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                    setRegimeNormale(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Numéro IFU'
                                value={numeroIFU}
                                onChange={(e)=> {
                                    setNumeroIFU(e.target.value)
                                }}
                            ></TextField>                           
                          </Stack>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {modifierClient()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>
            
        </div>
    );
    
}