import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../composants/nav.js';
import './dasboard.css';
import { Autocomplete ,CircularProgress,MenuItem,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import axios from 'axios';
import { format } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import m2agroup from './images/m2a.png';
import B from './images/B.png';
import B2 from './images/B2.png';
import H from './images/H.png';
import hand from './images/hand.png';
import laptop from './images/laptop.png';
import lock from './images/lock.png';
import T from './images/T.png';
import techni from './images/techni.png';
import wallet from './images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://backend.bt-support.net/api/';


export default function ReportingEquipement() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listCommentaire, setListCommentaire] = useState([]);
    const { compte_id } = useParams();
    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');

    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 5;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listCommentaire.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listCommentaire.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = 5;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };


    const { numeroSerie } = useParams();

    
      
    const request = async () => {
      try
      {
        await axios.get(`${BACKEND_URL}equipement/equipementMaintenue/commentaire/${numeroSerie}/${compte_id}`)
        .then(({data}) => {
            setListCommentaire(data);
            console.log(listCommentaire);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

   
    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };
    
        const handlePrint = () => {
          const printWindow = window.open('', '_blank');
        
        const tableRows = listCommentaire.map((row, index) => (
          `<tr>
            <td>${index + 1}</td>
            <td>${format(new Date(row.created_at), 'dd-MM-yyyy')}</td>
            <td>${row.name}</td>
            <td color: row.etatApres === 'En service' ? 'green' : 'red'>${row.etatApres}</td>
            <td>${row.commentaire}</td>
          </tr>`
        )).join('');
        
        // Utilisez la chaîne HTML générée pour construire le document HTML
        const htmlContent = `
          <html lang="fr">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Tableau des maintenances. N-serie: ${numeroSerie}</title>
              <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
                .header {
                  border-bottom: 2px solid #000;
                  margin-bottom: 20px;
                  padding-bottom: 10px;
              }
              .header img {
                  width: 200px;
                  height: auto;
                  float: left;
                  margin-right: 20px;
              }
              .header h1,
              .header p {
                  margin: 0;
                  padding: 0;
              }
              /* Aligne les éléments pour l'impression */
              .header h1 {
                  font-size: 24px;
                  line-height: 1.2;
              }
              .header p {
                  font-size: 14px;
                  line-height: 1.4;
              }
              </style>
            </head>
            <body>
            <div class="printable header">
                    <img src=${m2agroup} alt="Logo">
                    <h1>M2AGROUP</h1>
                    <p>Siege social 01 BP 6727 OUAGA 01</p>
                    <p>Téléphone BF :+226 71081010</p>
                </div>
              <h2>Tableau des maintenances. N-serie: ${numeroSerie}</h2>
              <table>
                <thead>
                  <tr>
                    <th>Numéro</th>
                    <th>Date de l'intervention</th>
                    <th>Technicien</th>
                    <th>Etat après</th>
                    <th>Commentaire</th>
                  </tr>
                </thead>
                <tbody>
                  ${tableRows}
                </tbody>
              </table>
            </body>
          </html>`;
        
        // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
        printWindow.document.write(htmlContent);
        printWindow.document.close();
        printWindow.print();
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const timer = setTimeout(() => {
              // Mettez à jour l'état pour arrêter le chargement après 3 secondes
              setLoading(false);
            }, 2000); // 3000 millisecondes = 3 secondes
        
            // Nettoyez le timer lorsque le composant est démonté
            const interval = setInterval(() => {
              setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
            }, 100);

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
      
          window.addEventListener('resize', handleResize);

            return () => {
              clearTimeout(timer);
              clearInterval(interval);
              window.removeEventListener('resize', handleResize);
          };
          }, []);

          const [loading, setLoading] = useState(true);
          const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  if(!loading) {
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`reporting/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handlePrint()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => handlePrint()} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => handlePrint()} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: windowWidth>700?5:2, 
                      marginRight: windowWidth>700?5:2,
                      borderRadius: 5, 
                      boxShadow: '0px 0px 5px #43766C',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    État des maintenances
                  </Stack>
                  <Stack sx={{marginBottom: 10}}>
                    {windowWidth>700?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#43766C',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={5}>Équipement N° série : {numeroSerie}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date de l'intervention</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Technicien</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Etat apres</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Commentaire</TableCell>                                          
                                    </TableRow>
                                    {listCommentaire.length === 0 ? (
                                        <TableRow>
                                          <TableCell colSpan={5} align="center" style={{fontWeight: 600, color: 'red'}}>
                                            Cet équipement n'a pas encore recu de maintenances antérieures.
                                            <br />
                                            Pensez à bien vérifier le numéro de série choisi. Merci
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        data.map((row, index) => (
                                          <TableRow key={index}>
                                            <TableCell align="center" style={{fontWeight: 600}}>{index + 1}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{format(new Date(row.created_at), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.name}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, color: row.etatApres === 'En service' ? 'green' : 'red'}}>{row.etatApres}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.commentaire}</TableCell>
                                          </TableRow>
                                        ))
                                      )}
                                                   
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 150, maxWidth: 500 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#43766C',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={5}>Équipement N° série : {numeroSerie}</TableCell>
                                    </TableRow>
                                </TableHead>
                                {listCommentaire.length === 0 ? (
                                  <TableBody>
                                        <TableRow>
                                          <TableCell colSpan={5} align="center" style={{fontWeight: 600, color: 'red'}}>
                                            Cet équipement n'a pas encore recu de maintenances antérieures.
                                            <br />
                                            Pensez à bien vérifier le numéro de série choisi. Merci
                                          </TableCell>
                                        </TableRow>
                                  </TableBody>
                                      ) : (                                  
                                <TableBody>                                  
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date de l'intervention</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Technicien</TableCell>                                  
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Etat apres</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Commentaire</TableCell>                                          
                                    </TableRow>                                    
                                    {data.map((row, index) => (
                                          <TableRow key={index}>                                            
                                            <TableCell align="center" style={{fontWeight: 600}}>{format(new Date(row.created_at), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.name}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, color: row.etatApres === 'En service' ? 'green' : 'red'}}>{row.etatApres}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.commentaire}</TableCell>
                                          </TableRow>                                                                              
                                      ))}
                                  </TableBody>
                                              
  )}
                            </Table>
                    </TableContainer>
  }
                  </Stack>  
                  <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                        <nav > 
                            <ul className='pagination'>
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={prePage}>Préc</a>
                                </li>
                                {
                                  getPageNumbersToShow().map((n,i) =>
                                  (
                                    <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                      <a href='#' className='page-link'
                                      onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  )
                                  )
                                }
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={nextPage}>Suiv</a>
                                </li>
                            </ul>
                        </nav>
                        <div>
                          <h5>Total : {listCommentaire.length}</h5> 
                        </div>
                </div>                 
            </Stack>
           
        </div>
        
    );

    function nextPage(){
      if( firstIndex+10 <listCommentaire.length)
      {
        setCurrentPage(currentPage + 1);
      }
    }

    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
      }
    }

    function changeCPage(id){
      setCurrentPage(id);
    }

  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}