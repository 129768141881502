import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {CircularProgress,Box, Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete} from '@mui/material';
import { Navigate, useNavigate,useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import { format } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Fuse from 'fuse.js';
import * as XLSX from 'xlsx'
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';
import m2agroup from '../images/m2a.png';
import { Tab } from 'bootstrap';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://backend.bt-support.net/api/';
  var countData=1;


export default function Intervention() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listInterv, setListInterv] = useState([]);
    const [listTicket, setListTicket] = useState([]);
    const [listUtilisateur, setListUtilisateur] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [ticket_id, setTicket_id] = useState('');
    const [interv_id, setInterv_id] = useState('');

    const [technicien_id, settechnicien_id] = useState('');
    const [mailTechni, setMailTechni] = useState('');
    const [commentaireInterv, setCommentaireInterv] = useState('');
    const [dateFinIntervention, setdateFinIntervention] = useState('');
    const [dateDebutIntervention, setdateDebutIntervention] = useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogDInterv, setOpenDialogDInterv] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const { compte_id } = useParams();

    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listInterv.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listInterv.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };
  

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );


      

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}intervention/${compte_id}`)
        .then(({data}) => {
            setListInterv(data);
            console.log(listInterv);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}utilisateurs/${compte_id}`)
        .then(({data}) => {
            setListUtilisateur(data);
            console.log(listUtilisateur);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}ticket_intervention/${compte_id}`)
        .then(({data}) => {
            setListTicket(data);
            console.log(listTicket);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
      setBackendErrorMessage('')
      setCommentaireInterv('')
      setMailTechni('')
                                  settechnicien_id('')
                                  setdateDebutIntervention('')
                                  setdateFinIntervention('')
                                  setCommentaireInterv('')
    };

    const handleClickOpenDialogDInterv = () => {
      setOpenDialogDInterv(true);
    };
  
    const handleCloseDialogDInterv = () => {
      setOpenDialogDInterv(false);
      setBackendErrorMessage('')
      setCommentaireInterv('')
    };
    
    const ajoutInterv = async () => {
      if(technicien_id==null || commentaireInterv==null)
      {
        setBackendErrorMessage('Veuillez entrez le nom du technicien')
      }
      else if(ticket_id== '' || ticket_id== null)
      {
        setBackendErrorMessage('Veuillez choisir le ticket ')
      }
      else if(dateDebutIntervention=='')
      {
        setBackendErrorMessage('Veuillez entrez la date de début')
      }
      else if(dateFinIntervention== '')
      {
        setBackendErrorMessage('Veuillez entrez la date de fin')
      }      
      else{
        await axios.post(`${BACKEND_URL}intervention/create/${compte_id}`, {
                             ticket_interv_id: ticket_id,
                             technicien_id: technicien_id,
                             dateDebutIntervention: dateDebutIntervention,
                             dateFinIntervention: dateFinIntervention,
                             commentaire: commentaireInterv
                  }).then((data) =>{
                    console.log(data)
                    window.alert('Intervention enregistré avec succes')
                    if(mailTechni=='' || mailTechni==null)
                    {
                      window.alert(`E-mail incorrect :${mailTechni}`)
                                  setOpenDialog(false);
                                  setBackendErrorMessage('');                                                                    
                                  setMailTechni('')
                                  settechnicien_id('')
                                  setdateDebutIntervention('')
                                  setdateFinIntervention('')
                                  setCommentaireInterv('')
                                  request();
                    
                    }
                    else{
                      axios.post(`${BACKEND_URL}envoie-mails`, {
                                        email: mailTechni                              
                                }).then((data1) => {
                                  request();
                                  console.log(data1)
                                  setOpenDialog(false);
                                  setBackendErrorMessage('');
                                  
                                  window.alert(`Mail envoyé avec succes à ${mailTechni}`);
                                  setMailTechni('')
                                  settechnicien_id('')
                                  setdateDebutIntervention('')
                                  setdateFinIntervention('')
                                  setCommentaireInterv('')
                                  request();
                    
                                })
                                .catch(error => {
                                  console.error('Erreur de requête :', error);
                                  window.alert(`Problème d\'envoies de mails à ${mailTechni}`)
                                  setOpenDialog(false);                                                                                                   
                                  setMailTechni('')
                                  settechnicien_id('')
                                  setdateDebutIntervention('')
                                  setdateFinIntervention('')
                                  setCommentaireInterv('')
                                  request();
                                  
                              });
                    }                                                                       
                  })
                  .catch(error => {
                    console.error('Erreur de requête :', error);
                    setBackendErrorMessage(error.response.data.error)
        });
      }
      };

      const debuterInterv = async () => {
        if(dateDebutIntervention=='')
        {
          setBackendErrorMessage('Veuillez entrez la date de début')
        }
        else if(dateFinIntervention== '')
        {
          setBackendErrorMessage('Veuillez entrez la date de fin')
        }
        else
        {
        await axios.put(`${BACKEND_URL}intervention/updateByTechni/${interv_id}/${compte_id}`, {
                             dateDebutIntervention: dateDebutIntervention,
                             dateFinIntervention: dateFinIntervention,
                             commentaire: commentaireInterv
                  }).then((data) =>{
                    console.log(data)
                    setOpenDialogDInterv(false);
                    request();
                    setBackendErrorMessage('')
                  })
                  .catch(error => {
                    console.error('Erreur de requête :', error);
                    setBackendErrorMessage(error.response.data.error)
        });
      }
      };

    const Deleterequest = async (approId) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
        try {
          await axios.delete(`${BACKEND_URL}intervention/destroy/${approId}/${compte_id}`);
          console.log(`Approvisionnement avec l'ID ${approId} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request()
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };

    const [searchQuery, setSearchQuery] = useState('');
    // Configuration de Fuse.js
const fuseOptions = {
 keys: [ 'codeTck', 'technicien',], // Les champs à rechercher
 includeScore: true,
};

// Initialiser un nouvel objet Fuse avec les options
const fuse = new Fuse(listInterv, fuseOptions);

// Fonction pour gérer le changement de la recherche
const handleSearchChange = (e) => {
 const query = e.target.value;
 setSearchQuery(query);

 // Effectuer la recherche et mettre à jour les résultats
 const results = fuse.search(query);

 if (query==''){
   //setApproData(results.map((result) => result.item));
   request()
 }
 else{
   setListInterv(results.map((result) => result.item));
 }

};

const handleExportExcel = () => {
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(listInterv);

  //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });

  // Mettre la première ligne en gras
  //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });

  XLSX.utils.book_append_sheet(wb, ws , `Interventions `);

  XLSX.writeFile(wb , `Interventions .xlsx`);

}
    
        const handlePrint = () => {
          const printWindow = window.open('', '_blank');
          
            const tableRows = listInterv.map((row, index) => (
              `<tr>
                <td>${index + 1}</td>
                <td>${row.codeTck}</td> 
                <td>${row.name}</td>
                <td>${row.dateDebutIntervention==null ? '--:--': row.dateDebutIntervention}</td>      
                <td>${row.dateFinIntervention==null ? '--:--': row.dateFinIntervention}</td>                                                         
                <td>${row.commentaire==null ? '----': row.commentaire}</td>
              </tr>`
            )).join('');
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Tableau des interventions</title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                    .header {
                      border-bottom: 2px solid #000;
                      margin-bottom: 20px;
                      padding-bottom: 10px;
                  }
                  .header img {
                      width: 200px;
                      height: auto;
                      float: left;
                      margin-right: 20px;
                  }
                  .header h1,
                  .header p {
                      margin: 0;
                      padding: 0;
                  }
                  /* Aligne les éléments pour l'impression */
                  .header h1 {
                      font-size: 24px;
                      line-height: 1.2;
                  }
                  .header p {
                      font-size: 14px;
                      line-height: 1.4;
                  }
                  </style>
                </head>
                <body>
                    <div class="printable header">
                        <img src=${m2agroup} alt="Logo">
                        <h1>M2AGROUP</h1>
                        <p>Siege social 01 BP 6727 OUAGA 01</p>
                        <p>Téléphone BF :+226 71081010</p>
                    </div>
                  <h2>Tableau des interventions</h2>
                  <table>
                    <thead>
                      <tr>
                      <th>Numéro</th>
                      <th>Ticket</th>
                      <th>Technicien</th> 
                      <th>Date de début</th>
                      <th>Date de fin</th>
                      <th>Commentaire</th>   
                       </tr>
                    </thead>
                    <tbody>
                      ${tableRows}
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const timer = setTimeout(() => {
                // Mettez à jour l'état pour arrêter le chargement après 3 secondes
                setLoading(false);
              }, 3000); // 3000 millisecondes = 3 secondes
          
              // Nettoyez le timer lorsque le composant est démonté
              const interval = setInterval(() => {
                setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
              }, 300);

              const handleResize = () => {
                setWindowWidth(window.innerWidth);
            };
    
            window.addEventListener('resize', handleResize);

              return () => {
                clearTimeout(timer);
                clearInterval(interval);
                window.removeEventListener('resize', handleResize);
            };
          }, []);

          const [loading, setLoading] = useState(true);
      const [currentImage, setCurrentImage] = useState(0);


  const navigate = useNavigate();

  if(!loading) {
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id} />
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <div style={{marginRight: 'auto'}}><h3>Gestion des interventions</h3></div>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handleExportExcel()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => {} } >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => {}} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: windowWidth>700?5:2, 
                      marginRight: windowWidth>700?5:2, 
                      borderRadius: 5, 
                      boxShadow: '0px 0px 5px #0D1282',
                      backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des interventions
                  </Stack>
                  <TextField type="text" 
                          value={searchQuery} 
                          style={{marginBottom: 15, 
                            marginRight:'auto',
                           marginLeft:8}}
                          onChange={handleSearchChange} 
                          placeholder="Rechercher...">
                    </TextField>
                  <Stack alignItems='center'>
                  
                {windowWidth>700 ?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0D1282',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={7}>Interventions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Ticket</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Technicien</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date de début</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date de fin</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Commentaire</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                            
                                    </TableRow>
                                        {data.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.codeTck}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.name}</TableCell>
                                            <TableCell align="left" style={{fontWeight: row.dateDebutIntervention==null ? 0: 600}}>{row.dateDebutIntervention==null ? '--:--': format(new Date(row.dateDebutIntervention), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="left" style={{fontWeight: row.dateFinIntervention==null ? 0: 600}}>{row.dateFinIntervention==null ? '--:--':format(new Date(row.dateFinIntervention), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="left" style={{fontWeight: row.commentaire==null ? 0: 600}}>{row.commentaire==null ? '----': row.commentaire}</TableCell>                                            
                                            <TableCell align="left" style={{fontWeight: 600}}>                                                
                                                    <Tooltip title='Modifier'>
                                                        <IconButton style={{ /*color: '#40A2E3'*/ }} disabled onClick={()=>{}} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip> 
                                                    {/*<Tooltip title='Débuter une intervention'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={ async ()=>{
                                                                  setInterv_id(row.id);
                                                                  
                                                                  handleClickOpenDialogDInterv()
                                                          }} >
                                                          <CheckCircleIcon />
                                                        </IconButton>
                                                        </Tooltip>*/}
                                                    <Tooltip title="Rapport d'intervention">
                                                        <IconButton style={{ color: '#1D2B53' }} onClick={ async ()=>{
                                                                  
                                                          navigate(`${row.ticket_interv_id}/rapport/${row.id}/${row.refidagence}/${compte_id}`)
                                                          }} >
                                                          <DescriptionIcon />
                                                        </IconButton>
                                                    </Tooltip>                         
                                                    <Tooltip title='Voir'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={ async ()=>{
                                                                  
                                                          navigate(`${row.refidagence}/equipements/${row.id}/${compte_id}`)
                                                          }} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                                                                                                       
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                 
                                            </TableCell>
                                                    </TableRow>
                                                      ))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer sx={{ minWidth: 280, maxWidth:windowWidth<500?300:500 }} component={Paper}>
                            <Table  aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0D1282',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20 }} colSpan={7}>Interventions</TableCell>
                                    </TableRow>
                                </TableHead>
                                {data.map((row, index) => (
                                <TableBody>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600,color: 'black', backgroundColor: '#E3FEF7'}} colSpan={2}>Ticket</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>{row.codeTck}</TableCell>
                                    </TableRow>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Technicien</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Commentaire</TableCell>
                                    </TableRow>
                                   <TableRow>
                                   <TableCell align="center" style={{fontWeight: 600}}>{row.name}</TableCell>                                            
                                   <TableCell align="center" style={{fontWeight: row.commentaire==null ? 0: 600}}>{row.commentaire==null ? '----': row.commentaire}</TableCell>                                                                                        
                                    </TableRow> 
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date de début</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date de fin</TableCell>    
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: row.dateDebutIntervention==null ? 0: 600}}>{row.dateDebutIntervention==null ? '--:--': format(new Date(row.dateDebutIntervention), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="center" style={{fontWeight: row.dateFinIntervention==null ? 0: 600}}>{row.dateFinIntervention==null ? '--:--':format(new Date(row.dateFinIntervention), 'dd-MM-yyyy')}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>                                        
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2}>Action</TableCell>                                            
                                    </TableRow>                                        
                                    <TableRow>                                                                                        
                                            <TableCell align="center" style={{fontWeight: 600}} colSpan={2}>                                                
                                                    <Tooltip title='Modifier'>
                                                        <IconButton style={{ /*color: '#40A2E3'*/ }} disabled onClick={()=>{}} >
                                                          <EditIcon />
                                                        </IconButton>
                                                    </Tooltip> 
                                                    {/*<Tooltip title='Débuter une intervention'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={ async ()=>{
                                                                  setInterv_id(row.id);
                                                                  
                                                                  handleClickOpenDialogDInterv()
                                                          }} >
                                                          <CheckCircleIcon />
                                                        </IconButton>
                                                        </Tooltip>*/}
                                                    <Tooltip title="Rapport d'intervention">
                                                        <IconButton style={{ color: '#1D2B53' }} onClick={ async ()=>{
                                                                  
                                                          navigate(`${row.ticket_interv_id}/rapport/${row.id}/${row.refidagence}/${compte_id}`)
                                                          }} >
                                                          <DescriptionIcon />
                                                        </IconButton>
                                                    </Tooltip>                         
                                                    <Tooltip title='Voir'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={ async ()=>{
                                                                  
                                                          navigate(`${row.refidagence}/equipements/${row.id}/${compte_id}`)
                                                          }} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                                                                                                       
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                 
                                            </TableCell>
                                                    </TableRow>
                                                    </TableBody>
                                                      ))}
                                                
                            </Table>
                    </TableContainer>
                    }
                    <div class={windowWidth>700?"flex-row":"flex-column"}>
                                    <nav > 
                                        <ul className='pagination'>
                                            <li className='page-item'>
                                              <a href='#' className='page-link'
                                                onClick={prePage}>Préc</a>
                                            </li>
                                            {
                                              getPageNumbersToShow().map((n,i) =>
                                              (
                                                <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                                  <a href='#' className='page-link'
                                                  onClick={() => changeCPage(n)}>{n}</a>
                                                </li>
                                              )
                                              )
                                            }
                                            <li className='page-item'>
                                              <a href='#' className='page-link'
                                                onClick={nextPage}>Suiv</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div>
                                      <h5>Total interventions:  {listInterv.length}</h5> 
                                    </div>
                                </div>
                  </Stack>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
                    Ajouter                      
            </Button> 
            </Stack>         
            <Dialog
                  //fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information de l'intervention"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >      
                        {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                        <Stack direction={ windowWidth>700?'row':'column'}
                        spacing={{sm: 2}}>
                                 <Autocomplete
                                   id="combo-box-demo"
                                   options={listUtilisateur}
                                   getOptionLabel={(option) => option.name}
                                   size='medium'
                                   style={{minWidth: windowWidth>700? 230 : 130,maxWidth: windowWidth>700? 230 : 220}}
                                   /*style={{width: width<800 ?  250 :(width*30)/100,
                                   backgroundColor: '#ffffff'}}*/
                                   value={technicien_id.value}
                                   onChange={(event, newValue) => {
                                     settechnicien_id(newValue ? newValue.id:'');
                                     console.log(newValue ? newValue.id:'');
                                     setMailTechni(newValue? newValue.email:"")
                                     console.log(newValue? newValue.email:'')
                                      // Nouvelle valeur sélectionnée

                                     //console.log(selectedFournisseur)
                                   }}
                                   renderInput={(params) => (
                                     <TextField {...params} label="Sélectionnez un technicien"  />
                                   )}
                                 />
                                 <Autocomplete
                                   id="combo-box-demo"
                                   options={listTicket}
                                   getOptionLabel={(option) => option.codeTck}
                                   size='medium'
                                   style={{minWidth: windowWidth>700? 230 : 130,maxWidth: windowWidth>700? 230 : 220}}
                                   /*style={{width: width<800 ?  250 :(width*30)/100,
                                   backgroundColor: '#ffffff'}}*/
                                   value={ticket_id.value}
                                   onChange={(event, newValue) => {
                                     setTicket_id(newValue ? newValue.id:'');
                                     console.log(newValue ? newValue.id:''); // Nouvelle valeur sélectionnée

                                     //console.log(selectedFournisseur)
                                   }}
                                   renderInput={(params) => (
                                     <TextField {...params} label="Sélectionnez un ticket"  />
                                   )}
                                 />                                 
                            </Stack>
                            <Stack direction={windowWidth>700? 'row': 'column'}
                                spacing={{sm: 2}}>
                                    <Stack direction={'column'}>
                                        <label> Date de début</label>
                                        <TextField type='date'                                         
                                            style={{minWidth: windowWidth>700? 230 : 130, maxWidth: windowWidth>700? 230 : 220}}
                                            value={dateDebutIntervention}
                                            onChange={(e)=> {
                                                setdateDebutIntervention(e.target.value)
                                            }}
                                        ></TextField>
                                    </Stack>
                                    <Stack direction={'column'}>
                                            <label> Date de fin</label>
                                        <TextField type='date'                                         
                                            style={{minWidth: windowWidth>700? 230 : 130, maxWidth: windowWidth>700? 230 : 220}}
                                            value={dateFinIntervention}
                                            onChange={(e)=> {
                                                setdateFinIntervention(e.target.value)
                                            }}
                                        ></TextField>
                                    </Stack>
                                          </Stack>
                            <Stack direction={'row'}
                                spacing={{sm: 2}}>
                                <TextField type='text' 
                                     label="Commentaire"
                                     value={commentaireInterv}
                                     style={{minWidth:windowWidth>700? 475 : 220, maxWidth: windowWidth>700? 230 : 300}}
                                     multiline
                                     rows={3}
                                     defaultValue="Default Value"
                                     onChange={(e)=> {
                                         setCommentaireInterv(e.target.value)
                                     }}
                                ></TextField>
                            </Stack>                                                                                                                                                      
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutInterv(ticket_id)}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>
                {/*<Dialog
                  fullScreen={fullScreen}
                  open={openDialogDInterv}
                  onClose={handleCloseDialogDInterv}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information de l'intervention"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                            <Stack direction={'row'}
                                spacing={{sm: 2}}>
                                    <Stack direction={'column'}>
                                        <label> Date de début</label>
                                        <TextField type='date'                                         
                                            style={{minWidth:230}}
                                            value={dateDebutIntervention}
                                            onChange={(e)=> {
                                                setdateDebutIntervention(e.target.value)
                                            }}
                                        ></TextField>
                                    </Stack>
                                    <Stack direction={'column'}>
                                            <label> Date de fin</label>
                                        <TextField type='date'                                         
                                            style={{minWidth:230}}
                                            value={dateFinIntervention}
                                            onChange={(e)=> {
                                                setdateFinIntervention(e.target.value)
                                            }}
                                        ></TextField>
                                    </Stack>
                            </Stack>
                            <Stack direction={'row'}
                                spacing={{sm: 2}}>
                                <TextField type='text' 
                                     label="Commentaire"
                                     value={commentaireInterv}
                                     style={{minWidth:475}}
                                     multiline
                                     rows={3}
                                     defaultValue="Default Value"
                                     onChange={(e)=> {
                                         setCommentaireInterv(e.target.value)
                                     }}
                                ></TextField>
                            </Stack>                                                                                                                                                      
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {debuterInterv()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogDInterv} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                                    </Dialog>*/}
        </div>
    );
}
else
{
    return(
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // 100% de la hauteur de la vue
        }}
      >
        <Stack direction={'column'} alignItems={'center'} spacing={1}>
            <CircularProgress />
            <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
        </Stack>
      </Box>
    );
}

 
function nextPage(){
  if(firstIndex+10 < listInterv.length)
  {
    setCurrentPage(currentPage + 1);
    countData=countData+10;
  }
}

function prePage(){
  if(firstIndex-1>0)
  {
    setCurrentPage(currentPage - 1);
    countData=countData-10;
    console.log(countData)
  }
}

function changeCPage(id){
  setCurrentPage(id);
  countData = ((id*10)-9)
}
}