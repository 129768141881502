import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncIcon from '@mui/icons-material/Sync';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { format } from 'date-fns';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import m2agroup from '../images/m2a.png';



var BACKEND_URL = 'https://backend.bt-support.net/api/';
export default function AfficherEquipementAtelier() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [equipementAtelier, setequipementAtelier] = useState([]);   
    const { equipement_id} = useParams();
    const { compte_id } = useParams();


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}equipementAtelier/show/${equipement_id}/${compte_id}`)
        .then(({data}) => {
            setequipementAtelier(data);
            console.log(equipementAtelier);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };
    
        const handlePrint = () => {
          const printWindow = window.open('ffffffff', '_blank');
          
            const tableRows = 
              `
              <tr>
                <td align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Client</td>
                <td align="center" style={{fontWeight: 600}}>${equipementAtelier.nomClientAtelier}</td>
              </tr>
              <tr>
                <td align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Équipement</td>
                <td align="center" style={{fontWeight: 600}}>${equipementAtelier.nomEquipementAtelier}</td>
              </tr>
              <tr>
                <td align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Référence</td>
                <td align="center" style={{fontWeight: 600}}>${equipementAtelier.reference}</td>
              </tr>
              <tr>
                <td align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Date d'entrée</td>
                <td align="center" style={{fontWeight: 600}}>${equipementAtelier.dateEntre}</td>
              </tr>
              <tr>
                <td align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Opérateur entrée</td>
                <td align="center" style={{fontWeight: 600}}>${equipementAtelier.operateurEntre}</td>
              </tr>
              <tr>
                <td align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Opérateur réception</td>
                <td align="center" style={{fontWeight: 600}}>${equipementAtelier.operateurReception}</td>
              </tr>
              <tr>
                <td align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Panne</td>
                <td align="center" style={{fontWeight: 600}}>${equipementAtelier.panne}</td>
              </tr>
              <tr>
                <td align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Commentaitre</td>
                <td align="center" style={{fontWeight: 600}}>${equipementAtelier.commentaire==null ? '' : equipementAtelier.commentaire}</td>
              </tr>
              <tr>
                <td align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Diagnostic</td>
                <td align="center" style={{fontWeight: 600}}>${equipementAtelier.diagnostic == null ? '' : equipementAtelier.diagnostic}</td>
              </tr>
              <tr>
                <td align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>État</td>
                <td align="center" style={{fontWeight: 600}}>${equipementAtelier.etat}</td>
              </tr>
              <tr>
                <td align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Statut</td>
                <td align="center" style={{fontWeight: 600}}>${equipementAtelier.sortie == null || 0 ? 'EN COURS' : 'TERMINER'}</td>
              </tr>

              <!-- Ajoutez les autres lignes ici -->
            `;
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title></title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                    .header {
                      border-bottom: 2px solid #000;
                      margin-bottom: 20px;
                      padding-bottom: 10px;
                  }
                  .header img {
                      width: 200px;
                      height: auto;
                      float: left;
                      margin-right: 20px;
                  }
                  .header h1,
                  .header p {
                      margin: 0;
                      padding: 0;
                  }
                  /* Aligne les éléments pour l'impression */
                  .header h1 {
                      font-size: 24px;
                      line-height: 1.2;
                  }
                  .header p {
                      font-size: 14px;
                      line-height: 1.4;
                  }
                  </style>
                </head>
                <body>
                <div class="printable header">
                    <img src=${m2agroup} alt="Logo">
                    <h1>M2AGROUP</h1>
                    <p>Siege social 01 BP 6727 OUAGA 01</p>
                    <p>Téléphone BF :+226 71081010</p>
                </div>
                  <h2>Détails de l'equipement</h2>
                  <table>
                    <thead>
                      <tr>
                       </tr>
                    </thead>
                    <tbody>
                      ${tableRows}
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
  
          window.addEventListener('resize', handleResize);
        
            return () => {
              window.removeEventListener('resize', handleResize);
          };
          }, []);


  const navigate = useNavigate();

    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`ateliers/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handlePrint()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => handlePrint()} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => handlePrint()} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: windowWidth>700?40:2, 
                      marginRight: windowWidth>700?40:2, 
                      borderRadius: 5,
                      paddingTop: 5,
                      paddingBottom: 5,
                      minWidth: windowWidth>700?600:200,
                      boxShadow: '0px 0px 5px #0802A3',
                      backgroundColor:'white'}}>                  
                  <Stack>
                    {windowWidth>700?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0802A3',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Details de l'équipement</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>                                    
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Client</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{equipementAtelier.nomClientAtelier}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Équipement</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{equipementAtelier.nomEquipementAtelier}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Référence</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{equipementAtelier.reference}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Date d'entrée</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{equipementAtelier.dateEntre}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Operateur entrée</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{equipementAtelier.operateurEntre}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Opérateur réception</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{equipementAtelier.operateurReception}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Panne</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{equipementAtelier.panne}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Commentaire</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{equipementAtelier.commentaire == null ? 'RAS' : equipementAtelier.commentaire}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600 , backgroundColor: '#F6F5F5'}}>Diagnostic</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{equipementAtelier.diagnostic}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600 , backgroundColor: '#F6F5F5'}}>État</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600 , color: equipementAtelier.etat == 'Fonctionne' ? 'green' : 'red'}}>{equipementAtelier.etat}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Statut</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{equipementAtelier.sortie == null || 0 ? 'EN COURS' : 'TERMINER'}</TableCell>
                                    </TableRow>
                                    
                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 150,maxWidth:300 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#0802A3',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Details de l'équipement</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>                                    
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}} colSpan={2}>Client</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{}} colSpan={2}>{equipementAtelier.nomClientAtelier}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Équipement</TableCell>     
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Référence</TableCell>                                                                                   
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{}}>{equipementAtelier.nomEquipementAtelier}</TableCell>
                                    <TableCell align="center" style={{}}>{equipementAtelier.reference}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Date d'entrée</TableCell>   
                                    <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Statut</TableCell>                                       
                                    </TableRow>                                    
                                    <TableRow>                                            
                                            <TableCell align="center" style={{}}>{equipementAtelier.dateEntre}</TableCell>
                                            <TableCell align="center" style={{}}>{equipementAtelier.sortie == null || 0 ? 'EN COURS' : 'TERMINER'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Operateur entrée</TableCell>  
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Opérateur réception</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" style={{}}>{equipementAtelier.operateurEntre}</TableCell>
                                            <TableCell align="center" style={{}}>{equipementAtelier.operateurReception}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Panne</TableCell>
                                            <TableCell align="center" style={{fontWeight: 800, backgroundColor: '#F6F5F5'}}>Commentaire</TableCell>

                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{}}>{equipementAtelier.panne}</TableCell>
                                            <TableCell align="center" style={{}}>{equipementAtelier.commentaire == null ? 'RAS' : equipementAtelier.commentaire}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600 , backgroundColor: '#F6F5F5'}}>Diagnostic</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600 , backgroundColor: '#F6F5F5'}}>État</TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{}}>{equipementAtelier.diagnostic}</TableCell>
                                            <TableCell align="center" style={{  color: equipementAtelier.etat == 'Fonctionne' ? 'green' : 'red'}}>{equipementAtelier.etat}</TableCell>
                                    </TableRow>                                    
                                    
                                </TableBody>
                            </Table>
                    </TableContainer>
}
                   
                  </Stack>                 
            </Stack>
            
        </div>
    );
    
}

